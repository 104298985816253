<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>이메일 인증 요청</span>
          </div>
          <div class="email_request">
            <div class="inform center">
              <div>
                <div class="h1 color">회원가입 인증 메일을 전송하였습니다.</div>
                <span class="color">{{ userId }}</span
                >으로 인증메일을 발송하였습니다.<br />
                이메일을 확인 후 인증을 진행해 주세요.<br />
                이메일 인증까지 완료해야 로그인이 가능합니다.<br />
                <br />
                <b>인증 유효 기간은 {{ deleteDt }} 까지 입니다.</b><br />
                24시간 이내로 인증을 받지 않으면 회원가입은 취소됩니다.
              </div>
            </div>
            <div class="caution">
              <div class="inner">
                <dl>
                  <dt>유의사항</dt>
                  <dd>
                    <div>
                      * 받은 메일함에 인증 메일이 없을 경우, 스팸 메일함을
                      확인해 주세요.
                    </div>
                    <div>
                      * 스팸 메일함에도 없을 경우, 하단의 ‘인증 메일 재발송’
                      버튼을 클릭한 후 다시 한번 메일을 확인해 주세요.
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnReSend">인증 메일 재발송</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'regist-mail-index',
  props: {
    userId: {
      type: [String],
      default: ''
    },
    deleteDt: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
